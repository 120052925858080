/* Page styling */
.analyticsPage {
  font-family: "Arial", sans-serif;
  background-color: #f5f5f5;
  padding: 20px;
  min-height: 100vh;
}

/* Container for the entire analytics section */
.analyticsContainer {
  max-width: 900px;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Page title */
.pageTitle {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 40px;
  color: #333;
  font-weight: bold;
}

/* Chart section */
.chartSection {
  margin-bottom: 50px;
  width: 50%;
}

/* .barChart {
  height: 200rem;
} */

/* Chart titles */
.chartTitle {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 20px;
  text-align: center;
}

/* Chart container to handle responsiveness */
.chartSection canvas {
  width: 100% !important;
  height: auto !important;
}

/* Loading text */
.loadingText {
  text-align: center;
  font-size: 1.2rem;
  color: #888;
}

/* Responsive behavior */
@media (max-width: 768px) {
  .analyticsContainer {
    padding: 20px;
  }

  .pageTitle {
    font-size: 1.5rem;
  }

  .chartTitle {
    font-size: 1rem;
  }

  .chartSection {
    /* margin-bottom: 30px; */
    width: 100%;
  }
}
