/* Overlay that darkens background */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup container */
.popup {
  background-color: #fff;
  padding: 2rem;
  width: 90%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  text-align: center;
  position: relative;
  animation: slideIn 0.3s ease-out;
}

/* Icon */
.icon {
  font-size: 3rem;
  color: #4caf50;
}

/* Title */
.title {
  margin: 0.5rem 0;
  color: #333;
  font-size: 1.8rem;
  font-weight: bold;
}

/* Message text */
.message {
  color: #666;
  font-size: 1rem;
  margin: 0.5rem 0 1.5rem;
}

/* Close button */
.closeButton {
  background-color: #4caf50;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.7rem 2rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.closeButton:hover {
  background-color: #388e3c;
}

/* Slide-in animation */
@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
