.resumeContainer {
  display: flex;
  height: 90vh;
  overflow: hidden;
  margin: 2.25rem;
  border: 2px dashed #3598db;
  border-radius: 15px;
}

.editorSection,
.previewSection {
  flex: 1;
  overflow-y: auto; /* Enable vertical scrolling */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

/* Hide the scrollbar for Webkit browsers */
.editorSection::-webkit-scrollbar,
.previewSection::-webkit-scrollbar {
  display: none;
}

.header {
  display: flex;
  gap: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
  position: sticky;
  top: 0;
  z-index: 10;
}

.header button {
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: #f0f0f0;
}

.activeButton {
  background-color: #007bff;
  color: white;
  border-color: #0056b3;
}

.contentArea {
  padding: 20px;
}

.sectionList {
  list-style-type: none;
  padding: 0;
}

.sectionItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
  margin-bottom: 10px;
  border: 1px solid #ccc;
}

.sectionItem button {
  margin-left: 5px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}

.sectionItem button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.previewSection {
  background-color: #f9f9f9;
}

.resumePreview {
  font-family: Arial, sans-serif;
}

.resumePreview h2 {
  font-size: 24px;
  color: #333;
}

.resumePreview p {
  font-size: 16px;
  color: #666;
}

.resumePreview h3 {
  margin-top: 20px;
  font-size: 18px;
  color: #444;
}
