/* .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
} */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px); /* Glassmorphism blur effect */
}

.popup {
  position: relative;
  background: #fff;
  /* padding: 20px; */
  border-radius: 8px;
  max-width: 90vw;
  /* width: 90%; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.content {
  /* margin-top: 10px; */
  max-height: 90vh;
  /* height: auto; */
  overflow-y: auto;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
  transition: color 0.3s;
  z-index: 2000;
}

.closeButton:hover {
  color: #007bff;
}
