/* Container for the entire comparison section */
.container {
  background-color: #f4f6f9; /* Light gray background */
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Title Styling */
.title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #2c3e50; /* Dark professional blue */
  margin-bottom: 15px;
}

/* Wrapper to make the table responsive */
.tableWrapper {
  overflow-x: auto;
  max-width: 100%;
}

/* Table Styling */
.table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #d1d9e6; /* Subtle border */
}

/* Table Head */
.table thead {
  background-color: #34495e; /* Dark professional blue */
  color: white;
}

/* Column Header Styling */
.fieldColumn,
.appColumn {
  padding: 15px;
  font-weight: bold;
  text-align: left;
  border-bottom: 2px solid #34495e;
  white-space: nowrap;
}

/* Leftmost column (Field names) */
.field {
  font-weight: bold;
  background-color: #eaf1f8; /* Soft blue */
  color: #34495e;
  padding: 12px;
  border-right: 1px solid #d1d9e6;
}

/* Data cells */
.dataCell {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #d1d9e6;
}

/* Notes Container */
.notesContainer {
  max-width: 10rem; /* Set a fixed width */
  max-height: 6rem; /* Set a fixed height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 8px;
  border: 1px solid #d1d9e6; /* Subtle border */
  background-color: #f8f9fa; /* Light background */
  border-radius: 6px;
  font-size: 0.9rem;
  line-height: 1.4;
  white-space: pre-wrap; /* Preserve line breaks */
}

/* Alternating row background */
.table tbody tr:nth-child(even) {
  background-color: #f7f9fc; /* Soft alternating row */
}

/* Responsive Table */
@media (max-width: 768px) {
  .table thead {
    font-size: 0.9rem;
  }

  .field,
  .dataCell {
    font-size: 0.9rem;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .table thead {
    font-size: 0.8rem;
  }

  .field,
  .dataCell {
    font-size: 0.8rem;
    padding: 6px;
  }
}
