.aboutUsContainer {
  font-family: "Mukta", Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.heroSection {
  background-color: #f8f9fa;
  padding: 4rem 2rem;
  text-align: center;
}

.heroTitle {
  font-size: 3rem;
  color: #343a40;
  font-family: "Lilita One", sans-serif;
  margin-bottom: 1rem;
}

.heroSubtitle {
  font-size: 1.25rem;
  color: #6c757d;
  margin-bottom: 2rem;
}

.teamSection,
.visionSection,
.contactSection {
  padding: 2rem 2rem;
  background-color: #fff;
}

.sectionTitle {
  font-size: 2rem;
  color: #343a40;
  text-align: center;
  margin-bottom: 1.5rem;
  font-family: "Lilita One", sans-serif;
}

.teamGrid {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: center; /* Center items horizontally */
  gap: 1.5rem; /* Reduce gap for better spacing on smaller screens */
  margin: 0 auto; /* Center the grid */
  text-align: center;
}

.teamMember {
  flex: 1 1 calc(33.333% - 1rem); /* Three columns on larger screens */
  max-width: 15rem; /* Limit max width of each item */
  min-width: 10rem; /* Minimum width for smaller screens */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.teamMember h3 {
  margin: 0;
}

.teamMember p {
  margin: 0;
}

.teamImage {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  object-fit: cover;
  /* margin-bottom: 1rem; */
  margin-bottom: 0;
}

.visionText,
.contactText {
  font-size: 1.125rem;
  text-align: center;
  margin: 0 auto;
  max-width: 600px;
  color: #6c757d;
}

.contactText a {
  color: #007bff;
  text-decoration: none;
}

.contactText a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .teamMember {
    flex: 1 1 calc(50% - 1rem); /* Two columns on tablets */
  }

  .teamImage {
    margin-bottom: 0;
  }
}

@media (max-width: 480px) {
  .teamGrid {
    gap: 2.5rem;
  }
  .teamMember {
    flex: 1 1 100%; /* Single column on phones */
  }

  .teamImage {
    width: 3rem; /* Adjust image size for smaller screens */
    height: 3rem;
    margin-bottom: 0;
  }
}
