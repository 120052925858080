/* Container for the pricing section */

.pricingRoot {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pricingSection {
  background-color: #f7f7f7;
  padding: 60px 20px;
  text-align: center;
  max-width: 1200px;
  width: 100%;
}

/* Title for the pricing section */
.pricingTitle {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
  font-weight: bold;
}

/* Container for the pricing plans */
.pricingPlans {
  display: flex;
  justify-content: space-around; /* Evenly space out the plans */
  gap: 20px;
  flex-wrap: wrap; /* Wrap on smaller screens */
}

/* Individual plan card */
.plan {
  background-color: #fff;
  padding: 1em 1em;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px; /* Fixed width for consistency */
  transition: transform 0.3s;
  text-align: left;
}

.plan:hover {
  transform: translateY(-5px); /* Slight lift on hover */
}

/* Highlight the most popular plan */
.planPopular {
  /* border: 2px solid #ffdd57;  */
  border: 2px solid #8dcf3f;
  background: linear-gradient(135deg, #eaffd3, #f7ffe8);
  /* #8dcf3f
  #78b336 */
}

/* Plan title */
.planTitle {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
}

/* Plan price */
.planPrice {
  font-size: 2rem;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 30px;
}

.planPrice span {
  font-size: 1rem;
  color: #777;
}

/* Plan features list */
.planFeatures {
  list-style-type: none;
  padding: 0;
  margin: 0 0 30px;
}

.planFeatures li {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.planFeatures li::before {
  content: "✔"; /* Checkmark before each feature */
  color: #007bff;
  margin-right: 10px;
}

/* Button for each plan */
.planButton {
  padding: 10px 20px;
  background-color: #007bff;
  /* background-color: rgb(22, 173, 237); */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s;
}

.planButton:hover {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .pricingPlans {
    flex-direction: column;
    align-items: center;
  }

  .plan {
    width: 100%; /* Full width on smaller screens */
    max-width: 400px; /* Max width for large phones */
  }
}
