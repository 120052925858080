.pagesContainer {
  display: flex;
  flex-direction: column; /* Stack A4 pages vertically */
  align-items: center; /* Center pages horizontally */
  gap: 20px; /* Space between pages */
  padding: 20px 26px; /* Padding at top and bottom */
  min-width: 100%;
}

.previewA4 {
  /* width: 210mm;
  height: 297mm;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20mm; */
  min-width: 100%;
  width: 100%; /* Take full width of the parent container */
  aspect-ratio: 210 / 297; /* A4 ratio - maintains A4 dimensions */
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 3.5% 4.5%; /* Adjust padding to your needs */
  overflow-y: auto;
}
