@import url("https://fonts.googleapis.com/css2?family=Lilita+One&family=Mukta:wght@200;300;400;500;600;700;800&display=swap");

.signupRoot {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* margin-top: 5rem; */
  height: auto; /* Full screen height */
  background-color: #f8f9fa; /* Light background color */
  padding-bottom: 32px;
  font-family: "Mukta", Arial, Helvetica, sans-serif;
}

.signupForm {
  background-color: rgb(255, 255, 255);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  width: 70%;
  max-width: 400px; /* Max width for the form */
  display: flex;
  flex-direction: column;
}

.signupTitle {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #343a40;
  font-size: 3rem;
  font-family: "Lilita One", Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.signupForm label {
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: #555;
  font-family: "Mukta", Arial, Helvetica, sans-serif;
}

.signupForm input {
  padding: 10px;
  margin-bottom: 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.signupForm input:focus {
  border-color: #007bff;
  outline: none;
}

.btn {
  padding: 10px;
  font-size: 1rem;
  /* background-color: #007bff; */
  background-color: #16aeee;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: "Mukta", Arial, Helvetica, sans-serif;
}

.btn:hover {
  /* background-color: #0056b3; */
  background-color: #0d688f;
}

.passwordContainer {
  position: relative;
  margin-bottom: 15px;
}

.passwordInput {
  width: 100%;
  margin-bottom: 0 !important;

  padding-left: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-right: 5rem !important; /* Space for the show/hide button */
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
}

.showPasswordBtn {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  background: #f5f5f5;
  border: none;
  border-radius: 3px;
  color: #333;
  font-size: 0.9rem;
  padding: 4px 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.showPasswordBtn:hover {
  background-color: #16aeee;
  color: white;
}

.error {
  color: red;
  font-size: 1rem;
  margin-bottom: 10px;
  font-family: "Mukta", Arial, Helvetica, sans-serif;
}

.termsSection {
  margin-bottom: 10px;
}

.termsLabel {
  font-size: 0.8rem !important;
  color: #d20d0d;
  /* margin-left: 10px; */
}

.termsLabel a {
  color: #007bff;
  text-decoration: none;
  font-family: "Mukta", Arial, Helvetica, sans-serif;
}

.termsLabel a:hover {
  text-decoration: underline;
}

.navbar {
  top: 0;
  width: 100%;
  position: sticky;
  z-index: 100;
}

.navContainer {
  display: flex;
  justify-content: space-between;
  max-width: 1300px;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 5rem;
  align-items: center;
  /* padding: 15px 30px; */
  background-color: #f8f9fa;
  border-bottom: 1px solid #e5e5e5;
  margin: 0 auto;
}

.logo {
  /* width: 3rem;
  height: 3rem;
  margin-right: 16px; */
  height: 2.5rem; /* Adjust logo height */
  width: auto;
}

.navLinks {
  display: flex;
  align-items: center;
}

.signInLink {
  font-size: 1.25rem;
  font-family: "Lilita One", Arial, Helvetica, sans-serif;
  color: black;
  text-decoration: none;
  padding: 0.5em 1em;
  border: 1px solid #5ab35c;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.signInLink:hover {
  background-color: #5ab35c;
  /* color: white; */
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ccc;
}

.divider::before {
  margin-right: 10px;
}

.divider::after {
  margin-left: 10px;
}

.dividerText {
  font-size: 1rem;
  color: #666;
}

/* Social buttons container */
.socialSignupContainer {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between the buttons */
}

/* Social buttons styling */
.socialButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 1rem;
  background-color: white;
  border: 2px solid #ccc;
  color: #555;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  font-family: "Mukta", Arial, Helvetica, sans-serif;
}

.socialButton:hover {
  background-color: #f0f0f0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Social icons */
.socialIcon {
  margin-right: 10px;
  /* font-size: 1.5rem; */
  width: 1.5rem;
  height: 1.5rem;
}

/* Customize button colors for each platform */
.socialButton:nth-child(1) {
  border-color: #db4437; /* Google red */
  color: #db4437;
}

.socialButton:nth-child(2) {
  border-color: #0077b5;
  color: #0077b5;
}
