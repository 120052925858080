@import url("https://fonts.googleapis.com/css2?family=Lilita+One&family=Londrina+Sketch&family=Mukta:wght@200;300;400;500;600;700;800&display=swap");

.pagesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  overflow-x: auto;
  flex-shrink: 0;
  transform: scale(0.3);
}

.previewA4 {
  width: 210mm;
  height: 297mm;
  padding: 20mm;
  /* min-width: 100%;
  width: 100%;
  aspect-ratio: 210 / 297; */
  background: rgb(255, 255, 255);
  box-shadow: rgb(255, 255, 255) 0px rgba(0, 0, 0, 0.1);
  /* padding: 3.5% 4.5%; */
  /* padding: 6.5% 7.5%; */
  overflow-y: hidden;
  text-align: left;
  transform-origin: top;
}

.renderedContent p {
  line-height: 1.2;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  /* line-height: 1.5; */
}

.addressSection {
  text-align: right;
}

@media (max-width: 912px) {
}

@media (max-width: 900px) {
}
