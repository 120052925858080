@import url("https://fonts.googleapis.com/css2?family=Lilita+One&family=Londrina+Sketch&family=Mukta:wght@200;300;400;500;600;700;800&display=swap");

:root {
  --primary-color: #3498db;
  --secondary-color: #f9f9f9;
  --text-color: #333;
  --border-color: #ddd;
  --hover-color: #2980b9;
  --background-color: #ffffff;
  --highlight-color: #f0f8ff;
  --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.container {
  display: flex;
  overflow: hidden;
  font-family: "Mukta", Arial, Helvetica, sans-serif;
}

.editorSection {
  width: 45%;
  padding: 20px;
  height: 100vh;
  box-sizing: border-box;
  /* overflow-y: auto; */
  overflow: auto;
  /* background-color: var(--background-color); */
  background-color: beige;
  border-right: 1px solid var(--border-color);
  box-shadow: var(--box-shadow);
}

.coverLetterForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* height: 100%;
  overflow-y: auto; */
}

.row {
  display: flex;
  gap: 15px;
}

.inputGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.coverLetterForm label {
  font-size: 0.9rem;
  color: var(--text-color);
  margin-bottom: 5px;
  font-weight: bold;
}

.coverLetterForm input,
.coverLetterForm textarea {
  padding: 12px 15px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  font-size: 1rem;
  color: var(--text-color);
  background-color: var(--highlight-color);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.coverLetterForm input:focus,
.coverLetterForm textarea:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 8px rgba(52, 152, 219, 0.5);
}
.coverLetterForm textarea {
  width: 100% !important;
  line-height: 1.6;
  letter-spacing: 0.7px;
  font-family: Arial, Helvetica, sans-serif;
}

.addressGroup {
  display: flex;
  width: 100%;
  gap: 6px;
  flex-direction: row;
  flex-wrap: wrap;
}

.previewSection {
  flex: 1;
  height: 100vh;
  overflow: auto;
  margin: 2em;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* align-items: center; */
  background-color: #eaeaea;
  flex-direction: row;
}

.toggleButtonContainer {
  display: none;
  width: 100%;
  text-align: center;
  padding: 15px 0;
  background-color: var(--primary-color);
  box-shadow: var(--box-shadow);
  position: sticky;
  top: 0;
  z-index: 10;
}

.toggleButton {
  padding: 12px 25px;
  background-color: white;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s ease;
  font-size: 1rem;
}

.toggleButton:hover {
  background-color: var(--primary-color);
  color: white;
}

@media (max-width: 768px) {
  .container {
    display: block;
  }

  .editorSection,
  .previewSection {
    width: 100%;
    height: 100%;
    border-right: none;
    overflow: auto;
  }

  .toggleButtonContainer {
    display: block;
  }

  .hideOnMobile {
    display: none;
  }
}
