/* General navbar styles */
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;

  /* background-color: rgb(22, 173, 237); */
  background-color: #31ade8;
  padding: 15px;
  position: sticky;
  top: 0; /* Keeps the navbar at the top of the viewport */
  z-index: 1000; /* Ensures it stays on top of other content */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease-in-out;
  height: 5rem;
}

.navbar:hover {
  /* background-color: #333; */
  background-color: rgb(64, 168, 212);
}

/* Logo styles */
.logoContainer {
  position: relative;
  cursor: pointer;
}

.logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.logoContainer:hover .logo {
  transform: scale(1.1);
}

/* Dropdown Menu */
.dropdownMenu {
  position: absolute;
  top: 60px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.dropdownMenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdownMenu ul li {
  padding: 12px 20px;
  text-align: center;
}

.dropdownMenu ul li:hover {
  background-color: #f2f2f2;
}

.dropdownMenu ul li a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  display: block;
  transition: color 0.3s ease;
}

.dropdownMenu ul li a:hover {
  color: #007bff;
}

/* Display dropdown with smooth fade */
.navbar:hover .dropdownMenu {
  opacity: 1;
  pointer-events: auto;
}

/* Mobile and responsive dropdown */
@media (max-width: 768px) {
  .navbar {
    padding: 10px;
  }

  .logo {
    width: 40px;
    height: 40px;
  }

  .dropdownMenu {
    top: 50px;
  }

  .dropdownMenu ul li {
    padding: 10px;
  }

  .dropdownMenu ul li a {
    font-size: 14px;
  }
}
