@import url("https://fonts.googleapis.com/css2?family=Lilita+One&family=Mukta:wght@200;300;400;500;600;700;800&display=swap");

.loginRoot {
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35rem;
  background-color: #f8f9fa;
}

.loginCard {
  /* background-color: orange; */
  align-items: center;
  justify-content: center;
  width: 25rem;
  height: 25rem;
  margin-top: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  background-color: white;
  border-radius: 10px; /* Rounded corners */
  text-align: center; /* Center content inside the card */
}

.loginTitle {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #343a40;
  font-size: 3rem;
  font-family: "Lilita One", Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.loginInput {
  width: 300px;
  font-family: "Mukta", Arial, Helvetica, sans-serif;
  /* margin-left: 50%;
  transform: translate(-50%, 0); */
}

.loginInput {
  width: 100%;
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  outline: none;
  padding: 10px;
  font-size: 13px;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.2),
    0 1px 1px rgba(255, 255, 255, 0.2);
  -webkit-transition: box-shadow 0.5s ease;
  -moz-transition: box-shadow 0.5s ease;
  -o-transition: box-shadow 0.5s ease;
  -ms-transition: box-shadow 0.5s ease;
  transition: box-shadow 0.5s ease;
}
.loginInput:focus {
  box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.4),
    0 1px 1px rgba(255, 255, 255, 0.2);
}

/* @import url(https://fonts.googleapis.com/css?family=Open+Sans); */
.btn {
  display: inline-block;
  padding: 4px 10px 4px;
  margin-bottom: 0;
  font-size: 1.2rem;
  font-family: "Mukta", Arial, Helvetica, sans-serif;
  /* line-height: 18px; */
  color: #333333;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -ms-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#ffffff),
    to(#e6e6e6)
  );
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(top, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr=#ffffff, endColorstr=#e6e6e6, GradientType=0);
  border-color: #e6e6e6 #e6e6e6 #e6e6e6;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border: 1px solid #e6e6e6;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.btn:hover,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled] {
  background-color: #e6e6e6;
}
.btnLarge {
  /* padding: 9px 14px; */
  /* font-size: 15px; */
  /* line-height: normal; */
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.btn:hover {
  color: #333333;
  text-decoration: none;
  background-color: #e6e6e6;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -ms-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}
.btnPrimary,
.btnPrimary:hover {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  color: #ffffff;
}
.btnPrimary.active {
  color: rgba(255, 255, 255, 0.75);
}
.btnPrimary {
  background-color: #4a77d4;
  background-image: -moz-linear-gradient(top, #6eb6de, #4a77d4);
  background-image: -ms-linear-gradient(top, #6eb6de, #4a77d4);
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#6eb6de),
    to(#4a77d4)
  );
  background-image: -webkit-linear-gradient(top, #6eb6de, #4a77d4);
  background-image: -o-linear-gradient(top, #6eb6de, #4a77d4);
  background-image: linear-gradient(top, #6eb6de, #4a77d4);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr=#6eb6de, endColorstr=#4a77d4, GradientType=0);
  border: 1px solid #3762bc;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.5);
}
.btnPrimary:hover,
.btnPrimary:active,
.btnPrimary.active,
.btnPrimary.disabled,
.btnPrimary[disabled] {
  filter: none;
  background-color: #4a77d4;
}
.btnBlock {
  width: 100%;
  display: block;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.signupBox {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loginLabel {
  font-family: "Mukta", Arial, Helvetica, sans-serif;
}

.btnSignup {
  text-align: center;
  text-decoration: none;
  font-family: "Lilita One", Arial, Helvetica, sans-serif;
  border: 1px solid lightgreen;
  border-radius: 5px;
  padding: 0.5rem;
  transform: scale(1.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
  transition: color 0.3s ease;
  color: black;
}

.btnSignup:hover {
  background-color: lightgreen;
}
