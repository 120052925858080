@import url("https://fonts.googleapis.com/css2?family=Lilita+One&family=Londrina+Sketch&family=Mukta:wght@200;300;400;500;600;700;800&display=swap");
html {
  font-size: 100%;
  /* font-size: 16px; */
  /* font-size: 62.5%; */
  height: 100%;
  /* background-color: rgb(15, 189, 233); */
  background-color: #f7f7f7;
}

/* rem and em do not depend on html font size, it will always be 16px in media queries? */
@media (max-width: 768px) {
  html {
    font-size: 10px;
    /* or any value you find optimal */
  }
}

/* font size (px)
10 / 12 / 14 / 16 / 18 / 20 /24/30/36/44/52/62/74/86/98

*/
