@import url("https://fonts.googleapis.com/css2?family=Lilita+One&family=Mukta:wght@200;300;400;500;600;700;800&display=swap");

.questionBox input {
  width: 200px;
  margin-bottom: 32px;
  height: 3rem;
  width: 220px;
  font-size: 1.2rem;
  border-radius: 30px;
  padding: 12px;
}

.questionBox {
  text-align: center;
  font-family: "Lilita One", Arial, Helvetica, sans-serif;
  font-size: 3rem;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers vertically */
  align-items: center; /* Centers horizontally */
}

.questionBox h2 {
  font-size: 3rem;
}

.summaryBox {
  text-align: center;
  font-family: "Lilita One", Arial, Helvetica, sans-serif;
  font-size: 3rem;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers vertically */
  align-items: center; /* Centers horizontally */
}

.summaryText {
  text-align: center;
}

.btnContinue {
  text-align: center;
  text-decoration: none;
  font-family: "Lilita One", Arial, Helvetica, sans-serif;
  border: 1px solid lightgreen;
  font-size: 1.5rem;
  border-radius: 5px;
  padding: 0.5rem;
  transform: scale(1.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
  transition: color 0.3s ease;
  color: black;
}

.btnContinue:hover {
  background-color: lightgreen;
}

.btnContinue:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.6;
}

.countrySelectionInfo {
  width: 80%;
  font-size: 0.9rem;
  padding-top: 9rem;
  position: absolute;
  top: 40%;
  z-index: -1;
}

.strategyDropdown {
  width: 200px;
  height: 3rem;
  padding: 0.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  appearance: none; /* Remove default arrow */
  background-image: url("../icons/green-leaf-icon.png");
  background-position: right 10px center;
  background-repeat: no-repeat;
}

.strategyDropdown :focus {
  outline: none;
  border-color: #007bff;
}
