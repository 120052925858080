.applicationsContainer {
  background-color: #f9f9f9;
  /* padding: 40px; */
  border-radius: 10px;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
  width: 80%;
  margin: 5px auto;
  font-family: "Mukta", sans-serif;
  min-height: 70vh;
}

.title {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  /* margin-top: 0px; */
}

.applicationsList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.applicationCard {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease;
  cursor: pointer;
}

.applicationCard:hover {
  transform: scale(1.02);
}

.applicationHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.applicationHeader h3 {
  font-size: 1.5rem;
  color: #333;
  margin: 0;
}

.companyName {
  font-size: 1.2rem;
  color: #888;
}

.applicationDetails {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.salary,
.status {
  font-size: 1rem;
  color: #555;
}

.salary strong,
.status strong {
  color: #4caf50;
}

.statusWrapper {
  text-align: right;
}

.status {
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1.2rem;
  color: white;
  font-weight: bold;
}

.offer {
  background-color: #4caf50; /* Green for Offer */
}

.declined {
  background-color: #f44336; /* Red for Declined */
}

.interviewed {
  background-color: #2196f3; /* Blue for Interview */
}

.applied {
  /* background-color: #03a9f4; */

  background-color: #ff9800;
}

.wishlisted {
  background-color: #9c27b0;
  /* Orange for Pending */
  /* background-color: #9c27b0; */
}

.archived {
  background-color: black;
}

@media (max-width: 768px) {
  .applicationsContainer {
    width: 95%;
  }

  .applicationDetails {
    flex-direction: column;
    align-items: flex-start;
  }

  .compareButton {
    bottom: 1rem;
    right: 1rem;
    padding: 1em;
    font-size: 0.9rem;
  }
}

.toolbarIconContainer {
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
  justify-content: space-between;
}

.addIcon {
  width: 28px;
  cursor: pointer;
}

.addIcon:hover {
  transform: scale(1.2);
}

.tableWrapper {
  margin: 20px auto;
  width: 100%;
  max-width: 100%;
  overflow-x: auto; /* Allow horizontal scrolling */
  border: 1px solid #ddd;
  min-height: 25rem;
}

.recordsTable {
  width: 100%;
  min-width: 800px; /* Minimum width to trigger horizontal scrolling if necessary */
  border-collapse: collapse;
}

.recordsTable th,
.recordsTable td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.recordsTable th {
  background-color: #f4f4f4;
  /* position: relative; */
}

.selectorHeader {
  text-align: center;
}
.appCheckbox {
  cursor: pointer;
  transform: scale(1.5);
}
/* .recordsTable td {
  font-size: 1.1rem;
} */

/* .statusHeaderText {
  color: #0056b3;
  font-weight: bold;
  font-size: 1.2em;
  background: linear-gradient(
    90deg,
    #e74c3c,
    #f39c12,
    #2ecc71,
    #3598db,
    #8e44ad
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */

.statusHeaderText {
  color: #0056b3;
  font-weight: bold;
  font-size: 1.2em;
  background: linear-gradient(
    90deg,
    #e74c3c,
    #f39c12,
    #2ecc71,
    #3598db,
    #8e44ad
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* ================ */
.headerWithMultiSelect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  /* width: fit-content; */
  width: 150px;
}

.multiSelectToggle {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.2em;
  color: #555;
}

.dropDownIcon {
  cursor: pointer;
  width: 1.5rem;
}

.multiSelectDropdown {
  position: absolute;
  top: calc(100% + 5px);
  right: 0; /* Align to the right of the header */
  /* right: calc(-50%); */
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  min-width: 150px;
}

.option {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.option:hover {
  /* background-color: #f0f0f0; */
  opacity: 0.8;
}

.selectedOption {
  background-color: #0056b3;
  color: white;
  font-weight: bold;
}

.wishlisted {
  background-color: #f39c12;
  color: white;
  font-weight: bold;
}

.applied {
  background-color: #3598db;
  color: white;
  font-weight: bold;
}

.interview {
  background-color: #8e44ad;
  color: white;
  font-weight: bold;
}

.offer {
  background-color: #2ecc71;
  color: white;
  font-weight: bold;
}

.declined {
  background-color: #e74c3c;
  color: white;
  font-weight: bold;
}

.archived {
  background-color: #6c757d;
  color: white;
  font-weight: bold;
}

/* Optional: Add an animation for a moving rainbow effect */
@keyframes rainbowText {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}

.recordsTable td {
  vertical-align: middle;
  word-wrap: break-word; /* Allow long words to break */
  overflow-wrap: break-word; /* Break long words when necessary */
  white-space: normal; /* Enable text wrapping */
  max-width: 200px; /* Optional: Limit the width of the cell to control text wrapping */
}

/* Optional: Add shadow for a better UI */
.tableWrapper {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: scroll;
}

/* Styles for clickable job title */
.jobTitle {
  font-size: 1.4rem; /* Slightly larger for a more prominent appearance */
  font-weight: bold; /* Make it stand out */
  max-width: 20rem;
  width: fit-content;
  color: #124360; /* Use a clean, modern blue color for the link */
  text-decoration: none; /* No underline by default */
  position: relative; /* For adding animation effects */
  cursor: pointer; /* Show a pointer when hovered over */
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for color and scale */
  display: inline-block; /* Allow animation on text */
}

/* Hover state */
.jobTitle:hover {
  color: #205b83; /* Change color on hover */
  /* text-decoration: underline; */
  transform: scale(1.05); /* Slight zoom in on hover */
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for 3D effect */
}
/* Optional: Add a glowing border on hover for extra effect */
/* .jobTitle:hover::after {
  content: ""; 
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: #0056b3; 
  transform: scaleX(0); 
  transition: transform 0.3s ease; 
}

.jobTitle:hover::after {
  transform: scaleX(1); 
} */

.companyContainer {
  /* position: relative; 
  display: inline-block;
  padding-right: 1.5rem;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  display: flex;
  align-items: center;
}

.companyIcon {
  /* position: absolute;
  width: 1.1rem;
  top: 50%; 
  right: 5%;
  transform: translateY(-50%); 
  cursor: pointer;
  color: #007bff;
  transition: color 0.3s ease; */
  width: 1.1rem;
  margin-left: 0.5em;
}

.companyIcon:hover {
  color: #0056b3; /* Change color on hover */
  cursor: pointer;
}

.companyIconContainer {
  position: relative;
  display: inline-block;
}

.companyInfoDropdown {
  position: absolute;
  width: 12rem;
  top: 1.5rem;
  left: 1.1rem;
  background-color: beige;
  border: 1px solid whitesmoke;
  border-radius: 5px;
  padding: 0.5em;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.compareButton {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  /* background-color: transparent;  */
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 1em;
  cursor: pointer;
  transition: transform 0.2s ease;
  background-color: beige;
  border: 1px solid black;
}

.compareButton:hover {
  transform: scale(1.1);
}

.compareButton:active {
  transform: scale(0.95);
}

/* PNG Icon Styling */
.compareIcon {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensure the image fits */
}

/* Popup banner styling */
.emptyWarningText {
  font-weight: bold;
  font-size: 1.8rem;
  color: gray;
  text-align: center;
}

.emptyWarningText p {
  display: inline-block;
  margin-top: 18px;
  width: 80%;
}

.trashSection {
  display: flex;
  justify-content: left;
  align-items: center;
  font-weight: bold;
}

.trashBin {
  height: 2rem;
  margin-right: 6px;
  transition: transform 0.3s ease-in-out;
}

.trashBin:hover {
  transform: scale(1.1);
  cursor: pointer;
}

/* Centered animation for 'congrats' */
.congratsAnimation,
.motivationAnimation {
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1em 2em;
  border-radius: 20px;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  z-index: 1000;
  animation: fadeInOut 3s ease-in-out;
}

/* Styling for 'congrats' animation */
.congratsAnimation {
  /* background-color: #ffe082; */
  background-color: #ffd700;
  background-color: #81c784;
  /* color: #000; */
  color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Styling for 'motivation' animation */
.motivationAnimation {
  background-color: #e57373; /* Light red for motivation */
  color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
  }
  10% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
  }
}

/* the animation when interview status is selected */
.interviewedEditPopup {
  background-color: #f8f1f9;
  border: 2px solid #8e44ad;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  width: 25rem;
  padding: 1.5em;
  text-align: center;
}

/* Popup Title */
.interviewedEditPopupTitle {
  font-size: 1.5rem;
  font-weight: bold;
  color: #8e44ad;
  margin-bottom: 0.8em;
}

/* Description */
.interviewedEditPopupDescription {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

/* Form Group */
.formGroup {
  margin-bottom: 16px;
  text-align: left;
}

.label {
  font-size: 0.9rem;
  font-weight: bold;
  color: #8e44ad;
  display: block;
  margin-bottom: 8px;
}

.input,
.textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 0.9rem;
  color: #333;
}

.input:focus,
.textarea:focus {
  border-color: #8e44ad;
  outline: none;
  box-shadow: 0 0 4px rgba(142, 68, 173, 0.5);
}

/* Textarea Specific */
.textarea {
  height: 80px;
  resize: none;
}

/* Actions */
.actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

.cancelButton,
.saveButton {
  flex: 1;
  padding: 10px 0;
  font-size: 0.9rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
}

.cancelButton {
  background-color: #f0f0f0;
  color: #555;
}

.cancelButton:hover {
  background-color: #ddd;
}

.saveButton {
  background-color: #8e44ad;
  color: #fff;
}

.saveButton:hover {
  background-color: #732d91;
}
