@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

.root {
  /* background-color: beige; */
  height: max-content;
}

.greeting {
  font-family: "Fredoka", sans-serif;
  text-align: center;
}

.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 1.5em;
  font-size: 1rem;
  margin-bottom: 1em;
}

.paginationButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-weight: bold;
}

.paginationButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.paginationButton:hover:not(:disabled) {
  background-color: #0056b3;
}

.pageInfo {
  font-weight: bold;
}

.pageSizeSelect {
  padding: 6px;
  border-radius: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
}
