.interviewedEditPopup {
  background-color: #f8f1f9;
  border: 2px solid #8e44ad;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  width: 25rem;
  padding: 1.5em;
  text-align: center;
}

/* Popup Title */
.interviewedEditPopupTitle {
  font-size: 1.5rem;
  font-weight: bold;
  color: #8e44ad;
  margin-bottom: 0.8em;
}

/* Description */
.interviewedEditPopupDescription {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

/* Form Group */
.formGroup {
  margin-bottom: 16px;
  text-align: left;
}

.label {
  font-size: 0.9rem;
  font-weight: bold;
  color: #8e44ad;
  display: block;
  margin-bottom: 8px;
}

.input,
.textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 0.9rem;
  color: #333;
}

.input:focus,
.textarea:focus {
  border-color: #8e44ad;
  outline: none;
  box-shadow: 0 0 4px rgba(142, 68, 173, 0.5);
}

/* Textarea Specific */
.textarea {
  height: 25vh;
  resize: none;
}

.salaryRangeContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rangeSeparator {
  font-weight: bold;
}

/* Actions */
.actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

.cancelButton,
.saveButton {
  flex: 1;
  padding: 10px 0;
  font-size: 0.9rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
}

.cancelButton {
  background-color: #f0f0f0;
  color: #555;
}

.cancelButton:hover {
  background-color: #ddd;
}

.saveButton {
  background-color: #8e44ad;
  color: #fff;
}

.saveButton:hover {
  background-color: #732d91;
}
