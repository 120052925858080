.statusDropdown {
  padding: 8px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 150px;
  color: white;
  transition: background-color 0.3s;
  font-family: "Lilita One", Arial, Helvetica, sans-serif;
}

/* Status-specific background colors */
.wishListed {
  background-color: #f39c12;
}

.applied {
  background-color: #3598db;
}

.interview {
  background-color: #8e44ad;
}

.offer {
  background-color: #2ecc71;
}

.declined {
  background-color: #e74c3c;
}

.archived {
  background-color: #6c757d;
}

/* Hover effect */
.statusDropdown:hover {
  opacity: 0.9;
}
