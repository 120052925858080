/* ResetPasswordPopup.module.css */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1500;
}

.modal {
  background: #ffffff;
  padding: 30px 20px;
  border-radius: 12px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.modalTitle {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 15px;
  font-weight: bold;
}

.inputField {
  width: 100%;
  padding: 10px;
  margin: 12px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1em;
  outline: none;
  transition: border-color 0.3s;
}

.inputField:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.button {
  padding: 10px 20px;
  margin: 10px 5px 0 5px;
  font-size: 1em;
  font-weight: 600;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buttonSubmit {
  background-color: #007bff;
}

.buttonSubmit:hover {
  background-color: #0056b3;
}

.buttonSubmit:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.6;
}

.buttonCancel {
  background-color: #6c757d;
}

.buttonCancel:hover {
  background-color: #5a6268;
}

.message {
  margin-top: 15px;
  color: #e63946;
  font-size: 0.9em;
}
