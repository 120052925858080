/* Footer styling */
/* Footer styling */
.footer {
  background-color: rgb(22, 173, 237);
  color: #f1f1f1; /* Light text color */
  padding: 40px 20px; /* Padding for space */
  width: 100%;
  min-height: 250px;
  /* height: 30vh; */
  font-family: "Arial", sans-serif;
}

.footerContainer {
  display: flex;
  justify-content: space-between; /* Evenly space the elements */
  align-items: center; /* Vertically center elements */
  max-width: 1200px; /* Restrict maximum width */
  margin: 0 auto; /* Center the footer content */
  flex-wrap: wrap;
  height: 100px; /* Set a consistent height for vertical alignment */
}

/* Logo section */
.footerLogo img {
  height: 30px; /* Logo height */
  width: auto;
  filter: brightness(1.5); /* Brighten the logo on dark background */
}

.footerLogo a {
  text-decoration: none;
}

/* Links section */
.footerLinks {
  display: flex;
  gap: 30px; /* Space between links */
  align-items: center; /* Ensure links are vertically centered */
}

.footerLink {
  color: #f1f1f1;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  padding: 5px 0;
  transition: color 0.3s;
}

.footerLink:hover {
  color: #ffdd57; /* Hover color for links */
}

/* Social media section */
.footerSocial {
  display: flex;
  gap: 15px; /* Space between social media icons */
  align-items: center; /* Vertically center icons */
}

.socialIcon {
  color: #f1f1f1;
  font-size: 1.5rem;
  text-decoration: none;
  transition: color 0.3s;
}

.socialMediaLogo {
  width: 30px;
  height: 30px;
}

.tiktokLogo {
  width: 25px;
  height: 25px;
}

.socialIcon:hover {
  color: #ffdd57; /* Hover color for social icons */
}

/* Bottom copyright */
.footerBottom {
  border-top: 1px solid #444; /* Separator line */
  text-align: center;
  padding: 20px 0;
  font-size: 0.9rem;
  color: #000000;
}

@media (max-width: 768px) {
  .footerContainer {
    flex-direction: column;
    text-align: center;
    height: auto; /* Adjust height for smaller screens */
  }

  .footerLinks,
  .footerSocial {
    margin: 2rem 0;
  }

  .footerLinks {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    column-gap: 3rem;
    justify-content: space-between;
  }
}
