.buttonBarContainer {
  display: flex;
  justify-content: space-between; /* Left and right groups spaced apart */
  align-items: center;
  margin-bottom: 20px; /* Adds space between buttons and the table */
  padding: 10px 0;
  font-family: "Mukta", Arial, Helvetica, sans-serif;
}

/* Style for the left group of buttons */
.leftGroup {
  display: flex;
  gap: 10px; /* Adds space between buttons */
}

/* Style for the right group containing the dropdown and New Application button */
.rightGroup {
  display: flex;
  align-items: center;
  gap: 10px; /* Adds space between the dropdown and the button */
}

/* Button styling */
.button {
  padding: 0.5em 1em;
  /* background-color: #6c757d; */
  /* color: white; */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 10rem;
  font-weight: bold;
  transition: background-color 0.3s;
}

.buttonArchive {
  background-color: #6c757d;
  color: white;
}

.buttonArchive:hover {
  background-color: #41464b;
}

/* .button:hover {
  background-color: #41464b; 
} */

.buttonWishlist {
  background-color: #f39c12;
  color: black;
}

.buttonWishlist:hover {
  background-color: #c27d0e;
}

.buttonActive {
  background-color: white;
  color: black;
  border: 3px solid #3598db;
}

.buttonActive:hover {
  /* transform: scale(1.1); */
}

/* Dropdown container */
.dropdownContainer {
  display: flex;
  align-items: center;
}

/* Dropdown styling */
.dropdown {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
}

/* New Application button styling */
.newAppButton {
  padding: 8px 16px;
  background-color: #3598db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1.1rem;
  font-weight: 600;
}

.newAppButton:hover {
  background-color: #0d688f; /* Darker shade on hover */
}

@media (max-width: 768px) {
  .buttonBarContainer {
    flex-direction: column;
    justify-content: center;
    row-gap: 2em;
  }

  .rightGroup {
    flex-direction: column;
    justify-content: center;
  }
}
