@import url("https://fonts.googleapis.com/css2?family=Lilita+One&family=Londrina+Sketch&family=Mukta:wght@200;300;400;500;600;700;800&display=swap");

.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #16aeee;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1300px;
  height: 5rem;
  margin: 0 auto;
  padding: 0 2rem;
}

.logo {
  display: flex;
  align-items: center; /* Vertically center-align image and text */
  text-decoration: none;
}

.logoText {
  font-size: 1.5rem; /* Adjust text size */
  color: #333; /* Adjust color as needed */
  font-family: "Lilita One", Arial, sans-serif; /* Optional font style */
  margin-left: 8px;
}

.menuIcon {
  display: none;
  cursor: pointer;
}

.iconImage {
  height: 2.5rem;
  width: auto;
}

/* Links */
.navLinks {
  display: flex;
  align-items: center;
}

.navLink {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  margin-left: 20px;
  transition: color 0.3s;
  font-family: "Lilita One", Arial, sans-serif;
  cursor: pointer;
}

.navLink:hover {
  color: #333;
}

/* Sign Up and Login buttons */
.signupBtn,
.loginBtn {
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  padding: 10px 20px;
  margin-left: 20px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  font-family: "Lilita One", Arial, sans-serif;
}

.signupBtn {
  background-color: #fff;
  color: #333;
  border: 1px solid transparent; /* Add transparent border initially */
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}

.signupBtn:hover {
  background-color: #16aeee;
  border-color: white; /* Change border color on hover */
  color: white;
}

.loginBtn {
  background-color: transparent;
  border: 1px solid #fff;
}

.loginBtn:hover {
  background-color: #fff;
  color: #333;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .menuIcon {
    display: block;
  }

  .navLinks {
    position: absolute;
    top: 5rem;
    left: 0;
    width: 100%;
    background-color: #16aeee;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    height: 0; /* Collapsed */
    transition: height 0.4s ease-in-out;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .navLinks.active {
    height: auto;
    padding: 1rem 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .navLink {
    margin: 0.8rem 0;
    text-align: center;
    font-size: 1.2rem;
  }

  .navRight,
  .btnGroupOnMobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .signupBtn,
  .loginBtn {
    /* margin: 0.8rem 0;
    width: 80%;
    text-align: center; */
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .welcomeWords {
    /* font-size: 8rem;
    color: red; */
  }
}

.welcome {
  margin-top: 3em;
}

.welcomeWords {
  font-size: 3.5rem;
  text-align: center;
  font-family: "Lilita One", Arial, Helvetica, sans-serif;
  margin: 0 0.5em;
}

.productSec {
  margin-left: 3rem;
  margin-right: 3rem;
  font-family: "Mukta", Arial, Helvetica, sans-serif;
}

.productIntro {
  font-size: 2rem;
  font-family: "Mukta", Arial, Helvetica, sans-serif;
  text-align: center;
  line-height: 1.5em;
}

/* Container for all steps */
.stepsSection {
  width: 100%;
  padding-top: 2.5rem;
  font-family: "Mukta", Arial, Helvetica, sans-serif;
}

/* Style for each step */
.step {
  display: flex;
  align-items: center; /* Vertically centers the text and image */
  justify-content: space-between; /* Evenly space out text and image */
  /* padding: 40px 0;  */
  /* Space between each step */
  height: 25rem;
}

/* Text content in each step */
.stepText {
  /* Ensure the text takes up equal space */
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stepText h2 {
  font-size: 2rem;
  color: #333;
  margin: 0;
}

.stepText p {
  font-size: 1.2rem;
  color: #666;
  width: 50%;
  margin: 0;
}

/* Image content in each step */
.stepImage {
  flex: 1; /* Ensure the image takes up equal space */
  display: flex;
  padding-left: 20px;
  justify-content: center;
}

.stepImage img {
  width: 100%; /* Make sure the image is responsive */
  max-width: 500px; /* Optional: Restrict the image's max width */
  height: auto;
  border-radius: 8px; /* Optional: Add some border radius for style */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
}
/* Responsive styles */
@media (max-width: 768px) {
  /* Stack text and images vertically */
  .step {
    flex-direction: column;
    height: auto; /* Let the height adjust naturally */
    margin-bottom: 20px;
  }

  .stepText,
  .stepImage {
    width: 100%; /* Take full width on smaller screens */
    text-align: center; /* Center align the text for smaller screens */
  }

  .stepText p {
    width: 100%; /* Full width for smaller screens */
    font-size: 1.1rem; /* Reduce font size slightly */
  }

  .stepImage img {
    max-width: 100%; /* Ensure the image takes full width on smaller screens */
  }

  .startButton {
    width: 80%; /* Make button wider on smaller screens */
  }
}

@media (max-width: 480px) {
  .stepText h2 {
    font-size: 1.5rem; /* Slightly smaller heading for very small screens */
  }

  .stepText p {
    font-size: 1.1rem; /* Even smaller paragraph text */
  }

  .startButton {
    width: 100%; /* Full width button for extra small screens */
  }
}

/* Reverse the order for alternate steps */
/* .stepReverse {
  flex-direction: row-reverse; 
} */

/* Container to center the button */
.startButtonContainer {
  display: flex;
  justify-content: center; /* Centers the button horizontally */
  align-items: center; /* Centers the button vertically */
  height: 5rem; /* Takes full viewport height to center vertically */
  background-color: #f7f7f7;
  /* padding: 20px; */
  text-align: center;
}

/* Button styling */
.startButton {
  padding: 0.5em 1em;
  background-color: rgb(22, 173, 237);
  width: auto;
  border: none;
  border: 1px dashed rgb(22, 173, 237);
  border-radius: 8px; /* Rounded corners */
  font-size: 2rem; /* Adjust font size */
  cursor: pointer; /* Change cursor on hover */
  transition: background-color 0.3s, transform 0.3s;
  font-family: "Lilita One", Arial, Helvetica, sans-serif;
  /* font-family: "Londrina Sketch", sans-serif; */
  /* background-color: white; */
  font-weight: 600;
  text-decoration: none;
  color: black;
  letter-spacing: 1px;
}

/* Hover effect */
.startButton:hover {
  color: black;
  transform: scale(1.1); /* Slightly increase size on hover */
}

.startButton:visited {
  color: black;
}

/* Main container for the testimonial section */
.testimonialSection {
  background-color: #f9f9f9; /* Light background color for contrast */
  padding: 0 1.5em; /* Padding around the section */
  /* padding-top: 20px; */
  text-align: center;
  font-family: "Mukta", Arial, Helvetica, sans-serif;
}

/* Title for the testimonial section */
.testimonialTitle {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 40px;
  font-weight: bold;
  font-family: "Mukta", Arial, Helvetica, sans-serif;
  /* #8dcf3f
  #78b336 */
}

/* Container for testimonial cards */
.testimonialCards {
  display: flex;
  justify-content: space-around; /* Evenly space the testimonial cards */
  align-items: stretch; /* Make sure the cards are of equal height */
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
  gap: 20px; /* Space between each card */
}

/* Each testimonial card */
.testimonialCard {
  background-color: #fff;
  padding: 20px;
  width: 300px; /* Fixed width for consistency */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 10px; /* Rounded corners */
  text-align: left;
  display: flex;
  flex-direction: column; /* Vertical layout for content */
  justify-content: space-between; /* Space between comment and user */
  transition: transform 0.3s;
}

.testimonialCard:hover {
  transform: translateY(-5px); /* Slight lift on hover for interactivity */
}

/* Comment text */
.comment {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 20px;
}

/* User attribution */
.user {
  font-size: 1rem;
  color: #999;
  text-align: right; /* Align user attribution to the right */
}

/* Responsive: Adjust layout for smaller screens */
@media (max-width: 768px) {
  .step {
    flex-direction: column;
  }

  .stepText,
  .stepImage {
    padding: 0;
  }

  .stepReverse {
    flex-direction: column; /* Stack everything vertically on mobile */
  }
}

/* Responsive: On small screens */
@media (max-width: 768px) {
  /* .menuOnMobile {
    display: block;
  }

  .linksOnMobile {
    display: none;
  }
  .navContainer {
    flex-direction: column;
    align-items: center;
  }

  .foldableLinks {
    display: none;
  } */

  /* .btnGroupOnMobile {
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  .startButton {
    font-size: 2rem; /* Slightly smaller text on mobile */
    padding: 0.5em 1em;
  }

  .testimonialCards {
    flex-direction: column; /* Stack the testimonial cards on mobile */
    align-items: center; /* Center the stacked cards */
  }

  .testimonialCard {
    width: 100%; /* Take full width on small screens */
    max-width: 350px; /* Optional: Set max width for consistency */
  }
}
