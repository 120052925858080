.homeContainer {
  width: 100%;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.topRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.searchBox {
  padding: 10px;
  font-size: 16px;
  width: 20%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.filterDropdown {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.newAppButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.newAppButton:hover {
  background-color: #0056b3;
}

.recordsTable {
  width: 100%;
  margin-top: 10px;
}

.tableHeader,
.tableRow {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.tableHeader {
  background-color: #f8f9fa;
  font-weight: bold;
  border-bottom: 2px solid #ccc;
}

.tableRow {
  align-items: center;
}

.tableHeader span,
.tableRow span {
  flex: 1; /* Flex to ensure equal width distribution */
  text-align: left;
  padding: 0 10px; /* Padding for space between columns */
}

.tableHeader span:first-child,
.tableRow span:first-child {
  flex: 2; /* Give the first column (Job Title) more space */
}

.tableHeader span:last-child,
.tableRow span:last-child {
  text-align: center; /* Align the status column in the center */
}

.tableContainer {
  margin: 20px auto;
  width: 100%;
  max-width: 1200px;
  overflow-x: auto;
}

.recordsTable {
  width: 100%;
  border-collapse: collapse;
}

.recordsTable th,
.recordsTable td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.recordsTable th {
  background-color: #f4f4f4;
}

.recordsTable td {
  vertical-align: middle;
}
