.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupBox {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 400px;
  max-width: 90%;
  animation: fadeIn 0.3s ease-out;
  font-family: "Mukta", sans-serif;
}

.popupBox h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

.popupBox p {
  font-size: 1.1rem;
  margin-bottom: 20px;
  color: #555;
}
.btnGroup {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  gap: 1rem;
}

.btnConfirm {
  background-color: #4caf50;
  color: white;
  padding: 0.8em 1em;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease;
  width: 6rem;
}

.btnConfirm:hover {
  background-color: #45a049;
}

.btnClose {
  background-color: #ff6b6b;
  color: white;
  padding: 0.8em 1em;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease;
  width: 6rem;
}

.btnClose:hover {
  background-color: #e85b5b;
}

/* Optional animation for smooth popup appearance */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
