@import url("https://fonts.googleapis.com/css2?family=Lilita+One&family=Mukta:wght@200;300;400;500;600;700;800&display=swap");

/* global */
.rootBox {
  display: flex; /* Make the container a flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  min-height: 75vh; /* Optional: Full viewport height or any other height */
}

/* toggle button style */
.switchContainer {
  margin-left: 3rem;
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: "Helvetica Neue", sans-serif;
}

.switchLabel {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4caf50;
}

.powerIcon {
  height: 26px;
  width: 26px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider:before {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
/* global */

/* job description */
.jdBox {
  background: #f5f5f5;
  font-family: "Mukta", Arial, Helvetica, sans-serif;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 70%;
  /* min-height: 75vh; */
  margin: 30px auto;
  text-align: center;
}

.title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.textBoxContainer {
  margin-bottom: 20px;
}

.textEditor {
  /* height: 20rem; */
}

.textArea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  resize: none;
  box-sizing: border-box;
  outline: none;
  transition: border 0.3s ease;
}

.textArea:focus {
  border-color: #6a5acd;
}

.extractButton {
  background: #6a5acd;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.extractButton:hover {
  background: #5a4caa;
}

.btnCreate:disabled,
.btnContinue:disabled,
.extractButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.6;
}

.keywordsContainer {
  margin-top: 20px;
  text-align: left;
}

.keywordsTitle {
  font-size: 20px;
  color: #444;
  margin-bottom: 10px;
}

.keywordsList {
  list-style: none;
  padding: 0;
}

.keywordItem {
  background: #e0e0e0;
  padding: 8px 15px;
  border-radius: 20px;
  margin: 5px 0;
  display: inline-block;
  font-size: 14px;
  color: #444;
  transition: background 0.3s ease;
  margin-right: 8px;
}

.keywordItem:hover {
  background: #d1d1d1;
}
/* job description */

.btnContinue {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 20px;
  font-family: "Lilita One", Arial, Helvetica, sans-serif;
}

.btnContinue:hover {
  background-color: #45a049;
}

/* resume part  */
/* 
.resumeBox {
  background: #f5f5f5;
  font-family: "Mukta", Arial, Helvetica, sans-serif;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 70%;
  margin: 30px auto;
  text-align: center;
}

.btnCreate {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 20px;
  font-family: "Lilita One", Arial, Helvetica, sans-serif;
  margin-right: 25px;
}

.btnCreate:hover {
  background-color: #45a049;
} */
.resumeBox {
  background: #f5f5f5;
  font-family: "Mukta", Arial, Helvetica, sans-serif;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 800px;
  margin: 30px auto;
  text-align: center;
}

/* Collection of resumes */
.resumeCollection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

/* Resume item */
.resumeItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 150px; /* Fixed width to prevent growth */
  height: 160px; /* Fixed height to maintain a uniform look */
  text-align: center;
  overflow: hidden;
}

/* Checkbox styling: Center checkbox using flexbox */
.resumeCheckbox {
  margin-bottom: 10px;
  align-self: center;
}
.childResumeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resumeIconContainer {
  /* background-color: #dcdcdc; */
  width: 60px;
  height: 60px;
  /* border-radius: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  width: 3.5rem;
}
/* Resume icon */
.resumeIcon {
  width: 60px;
  height: 60px;
}

/* Resume name styling with ellipsis and two-line wrap */
.resumeName {
  font-size: 1rem;
  font-family: "Mukta", Arial, Helvetica, sans-serif;
  max-width: 130px; /* Fixed max-width for name container */
  line-height: 1.2rem; /* Line height for readability */
  max-height: 2.4rem; /* Two lines max height */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to two lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* Show "..." for overflow text */
  word-wrap: break-word; /* Break words to fit within lines */
}

/* cover letter styling */
.clBtnGroup {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

/* Buttons styling */
.btnCreate,
.btnContinue {
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 20px;
  font-family: "Lilita One", Arial, Helvetica, sans-serif;
}

.btnCreate {
  background-color: #3598db;
  color: white;
  /* margin-right: 25px; */
}

.btnCreate:hover {
  background-color: #256a99;
}

/* summary page */
.btnWishlistIt {
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  width: 9rem;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 20px;
  font-family: "Lilita One", Arial, Helvetica, sans-serif;
  background-color: #f39c12;
  color: white;
}

.btnWishlistIt:hover {
  background-color: #aa6d0d;
}

.btnApplied {
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  width: 9rem;
  transition: background 0.3s ease;
  margin-top: 20px;
  font-family: "Lilita One", Arial, Helvetica, sans-serif;
  background-color: #3598db;
  color: white;
}

.btnApplied:hover {
  background-color: #2a7aaf;
}
.previewBox {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  /* border: 1px solid #ddd; */
  border-radius: 8px;
  /* background-color: #f9f9f9; */
}

.previewItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.previewItem h2 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.icon span {
  font-size: 24px;
  margin-bottom: 8px;
}

.buttons {
  display: flex;
  gap: 5px;
}

.buttons button {
  padding: 5px 10px;
  font-size: 12px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buttons button:hover {
  background-color: #0056b3;
}

.buttons button:active {
  background-color: #003f7f;
}

.fileIcon {
  width: 60px;
  height: 60px;
  margin-top: 0;
  margin-bottom: 10px;
}

.summaryList {
  text-align: center;
}

.list {
  list-style: none;
  padding: 0;
}

.listItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.listItem.active {
  opacity: 1;
}

.check {
  color: green !important;
  margin-left: 10px;
  font-size: 20px;
}

.cross {
  color: red;
  margin-left: 10px;
  font-size: 20px;
}

.loader {
  width: 16px;
  height: 16px;
  border: 3px solid lightgray;
  border-top: 3px solid blue;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-left: 10px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .previewBox {
    flex-direction: column; /* Stack items vertically */
    gap: 1em; /* Adjust spacing */
  }

  .item {
    min-width: 100%; /* Items take full width */
  }
}
/* summary page */

/* .btnContinue {
  background-color: #007bff;
  color: white;
}
.btnContinue:hover {
  background-color: #0056b3;
} */

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .resumeItem {
    width: 120px; /* Slightly smaller width for tablets */
  }
}

@media (max-width: 480px) {
  .resumeBox {
    padding: 15px;
  }

  .btnCreate,
  .btnContinue {
    font-size: 1rem;
    padding: 10px 15px;
  }
}

/* ****************************** */
/* jobinfo style */
.form {
  max-width: 1000px;
  margin: 0 auto;
}

.inputGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Always two columns */
  gap: 20px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .inputGrid {
    grid-template-columns: 1fr; /* Stack in one column for smaller screens */
  }
}

.inputGroup {
  display: flex;
  flex-direction: column;
}

.inputGroup label {
  font-weight: bold;
  font-family: "Mukta", Arial, sans-serif;
  margin-bottom: 8px;
}

.inputField {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  font-family: "Mukta", Arial, sans-serif;
}

.inputField:focus {
  border-color: #4caf50;
  outline: none;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.3);
}

/* Salary range styling */
.salaryRangeContainer {
  display: flex;
  align-items: center;
}

.salaryInput {
  width: 45%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  font-family: "Mukta", Arial, sans-serif;
}

.salaryInput:focus {
  border-color: #4caf50;
  outline: none;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.3);
}

.hyphen {
  margin: 0 10px;
  font-size: 1.5rem;
  color: #333;
}
