.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  /* background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  text-align: center;
  position: relative; */
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.jobSection {
  margin-bottom: 20px;
}

.jobDescription,
.coverLetter {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
  max-height: 15rem;
  overflow-y: auto;
  text-align: left;
  border: 1px solid #ddd;
  white-space: pre-wrap;
}

.btnResumeReview {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 20px;
  text-decoration: none;
  transition: background 0.3s ease;
}

.btnResumeReview:hover {
  background-color: #45a049;
}

.btnClose {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

.btnClose:hover {
  background-color: #e53935;
}
