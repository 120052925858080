.feedbackPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f5f7fa;
  border-radius: 8px;
  max-width: 600px;
  margin: 2rem auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 0.5rem;
  font-family: "Lilita One", Arial, sans-serif;
}

.subtitle {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 2rem;
  text-align: center;
}

.ratingSection {
  text-align: center;
  margin-bottom: 1.5rem;
}

.question {
  font-size: 1.2rem;
  color: #444;
}

.stars {
  display: flex;
  gap: 5px;
}

.star {
  font-size: 1.8rem;
  cursor: pointer;
  transition: transform 0.2s ease, color 0.2s ease;
}

.filledStar {
  color: #ffd700; /* Gold color for filled stars */
}

.outlinedStar {
  color: #ccc; /* Grey color for outlined stars */
}

.star:hover {
  transform: scale(1.2);
}

.textArea {
  width: 100%;
  min-height: 120px;
  margin: 1rem 0;
  padding: 1rem;
  font-size: 1rem;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 6px;
  resize: none;
  transition: border-color 0.2s ease;
}

.textArea:focus {
  border-color: #16aeee;
  outline: none;
}

.submitBtn {
  padding: 0.8rem 2rem;
  background-color: #16aeee;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitBtn:hover {
  background-color: #1b88a2;
}

.submitBtn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.6;
}
