.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  z-index: 1000;
  padding: 20px; /* Add some padding to prevent text from touching edges */
  animation: fadeIn 0.25s ease-in-out; /* Add a fade-in animation */
  font-family: "Poppins", sans-serif; /* Use a modern font for better aesthetics */
  backdrop-filter: blur(10px); /* Glassmorphism blur effect */
}

/* Subtle shadow effect to create depth */
.overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.7);
  z-index: -1;
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.content {
  max-width: 90%;
  max-height: 80%;
  width: 90%;
  min-height: 70%;
  overflow-y: auto;
  background: #fff;
  color: #333;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #2ecc71;
  margin-bottom: 1em; /* Add margin below content */
}

.buttonsContainer {
  display: flex;
  gap: 10px;
  margin-top: 20px; /* Ensure there's space above the buttons */
}

.closeButton,
.downloadButton {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bolder;
}

.closeButton {
  background-color: #e74c3c; /* Red for close */
  color: white;
}

.downloadButton {
  background-color: #2ecc71; /* Green for download */
  color: white;
}
