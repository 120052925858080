/* Container styling */
.settingsContainer {
  max-width: 800px;
  margin: 50px auto;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  font-family: "Mukta", Arial, Helvetica, sans-serif;
}

/* Title */
h1 {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

/* Form Group */
.formGroup {
  margin-bottom: 20px;
}

.formGroup label {
  display: block;
  font-size: 1rem;
  margin-bottom: 8px;
  color: #555;
}

.formGroup input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.strategyPart {
  display: none;
}

/* plan part  */
.currentPlanBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9fafb;
  margin-top: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.planDetails {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.planContent {
  font-weight: bold;
  font-size: 1.2rem;
  color: #333;
}

.planName {
  color: #16aeee;
  font-weight: bold;
}

.autoRenewLabel {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #666;
}

.autoRenewCheckbox {
  margin-right: 0.5rem;
  cursor: pointer;
}

.planActionBtn {
  background-color: #16aeee;
  color: #fff;
  border: none;
  padding: 0.6em 1.2em;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.planActionBtn:hover {
  background-color: #1b88a2;
}

.btnAllPlans {
  text-decoration: none;
  color: #fff;
  font-size: 1.5rem;
  padding: 10px 20px;
  margin-left: 20px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  font-family: "Lilita One", Arial, Helvetica, sans-serif;
  background-color: rgb(22, 173, 237);
  border: 1px solid #fff;
}

.btnAllPlans:hover {
  background-color: #fff;
  color: #333;
  border: 1px solid rgb(22, 173, 237);
}

/* Button Group */
.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 30px;
  justify-content: center;
}

.btn {
  flex: 1 1 calc(33.333% - 10px); /* Responsive buttons that take up a third of the row */
  padding: 15px 25px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Individual Button Styles */
.btnPrimary {
  background-color: #4caf50;
}

.btnPrimary:hover {
  background-color: #43a047;
  transform: translateY(-2px);
}

.btnSecondary {
  background-color: #ff9800;
}

.btnSecondary:hover {
  background-color: #fb8c00;
  transform: translateY(-2px);
}

.btnDanger {
  background-color: #e53935;
}

.btnDanger:hover {
  background-color: #d32f2f;
  transform: translateY(-2px);
}

/* Responsive behavior */
@media (max-width: 768px) {
  .currentPlanBox {
    flex-direction: column;
  }
  .planActionBtn {
    font-size: 0.8rem;
  }
  .buttonGroup {
    flex-direction: column;
    align-items: center;
  }

  .btn {
    flex: 1 1 100%;
    margin-bottom: 10px;
    max-width: 280px; /* Limit max width on smaller screens */
  }
}
