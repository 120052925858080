.popup {
  width: 90%;
  /* max-width: 700px; */
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  animation: fadeIn 0.3s ease-in;
  min-width: 35rem;
}

.header {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-align: center;
}

/* .header h2 {
  font-size: 1.5em;
  margin: 0;
  color: #333;
  margin-bottom: 16px;
} */
.jobTitle {
  font-size: 1.7em;
  margin: 0;
  line-height: 1.2;
  color: #333;
}

.title {
  font-weight: bold;
}

.company {
  font-style: italic;
  color: #888;
  font-weight: normal;
}

.details {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  color: #777;
  margin-top: 1em;
}

.status {
  padding: 0.4em 0.8em;
  border-radius: 5px;
  font-weight: bold;
  text-transform: capitalize;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.status.applied {
  background: #e1f5fe;
  color: #039be5;
}

/* Wishlisted */
.status.wishlisted {
  background: #fdf2e4; /* Lighter version of the background */
  color: #f39c12; /* Contrasting text color */
}

/* Interviewed */
.status.interview {
  background: #f3e5f5; /* Lighter purple for background */
  color: #8e44ad; /* Darker purple text */
  cursor: pointer;
}

.status.interview:hover {
  transform: scale(1.1);
}

/* Offer */
.status.offer {
  background: #eafaf1; /* Light green background */
  color: #2ecc71; /* Darker green text */
  cursor: pointer;
}
.status.offer:hover {
  transform: scale(1.1);
}

/* Declined */
.status.declined {
  background: #fdecea; /* Light red background */
  color: #e74c3c; /* Darker red text */
}

/* Archived */
.status.archived {
  background: #f0f1f2; /* Light gray background */
  color: #6c757d; /* Darker gray text */
}

/* .status.interviewed {
  background: #fff3e0;
  color: #ffb300;
} */

.cardsContainer {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  height: auto;
  min-height: 18rem;
}

.card {
  flex: 1;
  padding: 1em;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #f9f9f9;
  /* height: 14rem; */
  /* width: 100%; */
  overflow: hidden;
}

.card h3 {
  margin: 10px 0;
  font-size: 1.1em;
  color: #444;
}

.card p {
  flex-grow: 1;
  color: #666;
  font-size: 0.9em;
  /* background-color: rebeccapurple; */
  /* height: 100%; */
}

.cardContent {
  color: #666;
  font-size: 0.9em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Allows text to wrap naturally */
  word-wrap: break-word; /* Forces long words to wrap if needed */
  width: 100%;
  margin-bottom: 10px;
  flex-grow: 1;
}

.JDcardContent {
  color: #666;
  font-size: 0.8em; /* Reduced font size */
  line-height: 1.2; /* Tighter line spacing */
  padding: 5px; /* Reduce padding to maximize space */
  display: -webkit-box;
  -webkit-line-clamp: 6; /* Show up to 4 lines before ellipses */
  line-clamp: 6; /* Standard property for future compatibility */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-height: 6em; */
  /* Limits visible content to a fixed height */
  height: 100%;
  width: 100%;
  margin-bottom: 5px;
  flex-grow: 1;
  white-space: normal;
  word-wrap: break-word;
}

.icons {
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin-top: 15px;
  cursor: pointer;
}

.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.2s;
}

.notesIcon {
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
  margin-bottom: 0;
  margin-top: 0.5em;
}

.icon:hover {
  transform: scale(1.1);
}

.notesContainer {
  padding: 1em;
  padding-top: 2em;
  display: flex;
  /* width: 30rem;
  height: 50rem; */
  height: 80vh;
  width: 50vw;
  text-align: center;
  white-space: pre-wrap;
  flex-direction: column;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .popup {
    height: auto;
    max-height: 80vh;
    width: 80vw;
    overflow: auto;
    min-width: 20rem;
  }

  .notesContainer {
    width: 90vw;
    height: 70vh;
  }

  .card {
  }

  .cardContent {
    height: auto;
  }

  .header h2 {
    font-size: 1.3em;
  }

  .details {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .cardsContainer {
    flex-direction: column;
    gap: 1em;
  }
}

@media (max-width: 480px) {
  .popup {
    padding: 1em;
  }

  .header h2 {
    font-size: 1.2em;
  }

  .card h3 {
    font-size: 1em;
  }

  .icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
