/* NotFound.module.css */

.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
  padding: 2rem;
}

.title {
  font-size: 3rem;
  color: #ff6b6b;
  margin-bottom: 1rem;
}

.message {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 2rem;
}

.homeButton {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #3498db;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.homeButton:hover {
  background-color: #217dbb;
}
