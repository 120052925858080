/* Checkout Page Styles */
.checkoutContainer {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: "Helvetica Neue", sans-serif;
}

.checkoutHeader {
  text-align: center;
  margin-bottom: 2rem;
}

.companyLogo {
  height: 50px;
  width: 50px;
}

.secureCheckout {
  color: #4caf50;
  margin-top: 0.5rem;
}

.checkoutBody {
  display: flex;
  flex-direction: column;
}

.planSummary,
.billingInformation,
.orderSummary {
  margin-bottom: 2rem;
}

.planType {
  font-size: 1.15rem;
  font-weight: bold;
}

.planPrice {
  font-size: 1.5rem;
  color: #4caf50;
  margin: 0.5rem 0;
}

.planFeatures {
  list-style: none;
  padding: 0;
}

.billingInformation input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.orderRow,
.orderTotal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.orderTotal {
  font-size: 1.25rem;
  font-weight: bold;
}

.checkoutButton {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 1rem;
  font-size: 1.25rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.checkoutButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.checkoutButton:hover:not(:disabled) {
  background-color: #45a049;
}

/* Payment Success Page Styles */
.successContainer {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: "Helvetica Neue", sans-serif;
  text-align: center;
}

.successHeader h1 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.successBody h2 {
  font-size: 2rem;
  color: #4caf50;
  margin-bottom: 1rem;
}

.thankYou {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.planSummary {
  background-color: #f9f9f9;
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 8px;
}

.planSummary p {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.nextSteps {
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

.returnButton {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 1rem;
  font-size: 1.25rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.returnButton:hover {
  background-color: #45a049;
}
