.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
}

.logoContainer {
  position: relative;
  display: flex;
  width: 150px; /* Adjust this based on your logo size */
  justify-content: center;
  align-items: center;
  height: 150px;
  animation: pulse 1.5s infinite;
}

.logo {
  width: 50%;
  height: auto;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  70% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.spinner {
  margin-top: 20px;
  width: 40px;
  height: 40px;
  border: 5px solid rgba(0, 123, 255, 0.2);
  border-top: 5px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
